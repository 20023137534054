import React, { useContext, useRef, useState } from "react";
import HowItWorks from "./HowItWorks";
import MC3D from "assets/svgs/mingcute_cube-3d-line.svg";
import BiFolderX from "assets/svgs/bi_folder-x.svg";
import ArrowCube from "assets/svgs/arrow-cube.svg";
import BiFolder3D from "assets/svgs/bi_folder-3d.svg";
import VaddinChart3D from "assets/svgs/vaadin_chart-3d.svg";
import Portfolio from "./Portfolio";

import QuoteYellow from "assets/svgs/quote-yellow.svg";
import FeedbackFirst from "assets/images/feedback-1.png";
import FeedbackSecond from "assets/images/feedback-2.png";
import Modal3D from "assets/svgs/threed-model.svg";
import ConstructionSiteFlatline from "assets/svgs/construction-site-flatline.svg";
import ConstructionSiteOutline from "assets/svgs/construction-site-outline.svg";
import Footer from "components/Footer";
import { navigate } from "gatsby";
import { GlobalDispatchContext } from "../../../context/GlobalContextProvider";
import { CTA } from "./CTA";
import Supported from "./Supported";
import { faq } from "./data";
import "swiper/css";
import WhyConvert from "./WhyConvert";

const BIMObjectCreation = () => {
  const [showModal, setShowModal] = useState(false);
  const [category, setCategory] = useState("");
  const dispatch = useContext(GlobalDispatchContext);

  const handleClaim = () => {
    setCategory("Request for Project Trial");
    dispatch({
      type: "TOGGLE_CONTACT",
      payload: {
        contactLayer: true,
      },
    });
  };

  const handleDefault = () => {
    dispatch({
      type: "TOGGLE_CONTACT",
      payload: {
        contactLayer: true,
        message: `I'm interested in CAD to BIM Conversion`,
      },
    });
  };

  const projectUrl = `${process.env.GATSBY_VIEWER_URL}/projects/create`;

  return (
    <div>
      {/* Header */}
      <main>
        {/* Hero Section */}
        <section className="pt-10 md:pt-[57px] pb-9 bg-gray-50">
          <div className="tw-container">
            <div className="relative z-0">
              <BiFolderX className="absolute top-0 hidden left-10 md:block -z-10" />
              <ArrowCube className="absolute left-0 -bottom-[130px] w-[340px] md:block hidden -z-10" />
              <BiFolder3D className="absolute top-0 hidden right-10 md:block -z-10" />
              <VaddinChart3D className="absolute bottom-0 right-0 hidden md:block -z-10" />
              <div className="text-center max-w-[810px] mx-auto">
                <label className="inline-flex items-center gap-1 py-1 px-2 md:px-3 rounded-full bg-main-primary text-xs text-black font-medium tracking-[0.3px] uppercase mb-4 md:mb-6">
                  <MC3D />
                  Services
                </label>
                <h1 className="mb-5 text-4xl font-extrabold text-black md:text-5xl">
                  CAD to BIM Conversion
                </h1>
                <p className="mb-0 text-sm text-gray-500 md:text-base lg:text-xl">
                  Convert CAD & PDF drawings into detailed BIM models
                  in Revit, ArchiCAD, OpenBuildings, and more.
                </p>
                <div className="flex justify-center items-center gap-2 mt-5 md:mt-8 lg:mt-10 md:gap-4">
                  {/* <button
                    type="button"
                    className="transition-all duration-300 ease-in-out hover:opacity-80 text-sm md:text-base text-black font-medium capitalize rounded-md shadow-shadow-sm bg-main-primary py-[13px] px-4 md:px-[25px]"
                    onClick={() => handleDefault()}
                  >
                    Create BIM Objects
                  </button> */}
                  <button
                    type="button"
                    className="transition-all duration-300 ease-in-out hover:opacity-80 text-sm md:text-base text-white font-medium capitalize rounded-md shadow-shadow-sm bg-black py-[13px] px-4 md:px-[25px]"
                    onClick={() => navigate(`./#how-it-works`)}
                  >
                    How it Works
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Supported /> */}

        {/* Key Benefits */}
        <WhyConvert id="why-cad-to-bim" />

        {/* Why Us */}
        <section className="bg-white">
          <div className="pt-10 md:py-14 lg:py-16">
            <div className="tw-container">
              <h2 className="text-3xl md:text-4xl text-black font-extrabold -tracking-[0.9px] text-center mb-0">
                Why Choose Us
              </h2>
              <p className="text-center">
                Here's why we have been chosen to provide BIM services
                to hundreds of projects
              </p>
            </div>
          </div>
          <div className="py-5 md:py-8">
            <div className="tw-container">
              <div className="flex flex-col items-center justify-between w-full gap-4 md:gap-6 lg:gap-8 md:flex-row">
                <div className="md:w-[400px] lg:w-[500px] xl:w-[600px]">
                  <Modal3D className="h-[250px] sm:h-auto w-[300px] md:w-[400px] lg:w-[500px]" />
                </div>
                <div className="flex-1 text-left">
                  <h3 className="mb-2 font-semibold text-black md:text-xl lg:text-2xl lg:mb-3">
                    BIM Software Included
                  </h3>
                  <p className="mb-0 text-sm text-gray-500 md:text-base">
                    Avoid BIM & CAD software costs and licencing fees
                    when you work with our team. Tap on our pool of
                    BIM & CAD licences ranging from: Revit, Autocad,
                    Microstation, ArchiCAD, OpenBuildings, and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="py-5 md:py-8 bg-gray-50">
            <div id="bimCoordination" className="tw-container">
              <div className="flex flex-col-reverse items-center justify-between w-full gap-4 md:gap-6 lg:gap-8 md:flex-row">
                <div className="flex-1 text-left">
                  <h3 className="mb-2 font-semibold text-black md:text-xl lg:text-2xl lg:mb-3">
                    Onsite BIM Support
                  </h3>
                  <p className="mb-0 text-sm text-gray-500 md:text-base">
                    Schedule weekly meetings with our BIM Managers to
                    keep your BIM deliverables on track. For
                    mission-critical events, our BIM Managers are
                    available to attend meetings onsite.
                  </p>
                </div>
                <div className="md:w-[400px] lg:w-[500px] xl:w-[600px]">
                  <ConstructionSiteFlatline className="h-[250px] sm:h-auto w-[300px] md:w-[400px] lg:w-[500px]" />
                </div>
              </div>
            </div>
          </div>
          <div className="py-5 md:py-8">
            <div className="tw-container">
              <div className="flex flex-col items-center justify-between w-full gap-4 md:gap-6 lg:gap-8 md:flex-row">
                <div className="md:w-[400px] lg:w-[500px] xl:w-[600px]">
                  <ConstructionSiteOutline className="h-[250px] sm:h-auto w-[300px] md:w-[400px] lg:w-[500px]" />
                </div>
                <div className="flex-1 text-left">
                  <h3 className="mb-2 font-semibold text-black md:text-xl lg:text-2xl lg:mb-3">
                    BIM Submissions
                  </h3>
                  <div className="space-y-4">
                    <p className="mb-0 text-sm text-gray-500 md:text-base">
                      Whether you’re a contractor, architect, or
                      engineering firm, we understand your unique
                      needs. From BIM submissions for Corenet to
                      implementing advanced Virtual Design and
                      Construction (VDC) workflows, we provide the
                      digital tools and expertise to ensure your
                      project's success.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Our Services */}
        <HowItWorks id="how-it-works" handleDefault={handleDefault} />

        {/* Portfolio */}

        {/* FAQ */}
        <section className="py-10 md:py-16 bg-gray-50" id="faq">
          <div className="tw-container">
            <h2 className="text-md text-black font-extrabold -tracking-[0.9px] text-left mb-0">
              Frequently asked questions
            </h2>

            <div className="pb-5 mt-5 space-y-5 border-0 border-b border-gray-200 border-solid md:mt-6 lg:mt-8 md:pb-6 lg:pb-8 md:space-y-6 lg:space-y-8">
              {faq.map((faq) => (
                <div
                  className="flex flex-col items-start gap-3 pt-4 border-0 border-t border-gray-200 border-solid md:gap-6 lg:gap-8 md:pt-5 lg:pt-6 md:flex-row"
                  key={faq.id}
                  id={faq?.section}
                >
                  <div className="md:w-[300px] lg:w-[488px]">
                    <h4 className="mb-0 text-base font-semibold text-black ">
                      {faq.question}
                    </h4>
                  </div>
                  <div className="flex-1">
                    <p className="mb-0 text-sm text-gray-500 md:text-base">
                      {faq.answer}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <Compare /> */}
        </section>

        {/* Portfolio */}
        <Portfolio />

        {/* Feedback */}
        <section className="py-10 md:pt-[70px] lg:pt-[122px] md:pb-[70px] bg-[rgba(251,218,5,0.10)]">
          <div className="tw-container">
            <div className="grid md:grid-cols-3 gap-8 md:gap-10 lg:gap-[60px]">
              <div>
                <h2 className="text-3xl md:text-4xl text-black font-extrabold -tracking-[0.9px] mb-2">
                  Here's what our clients have to say.
                </h2>
              </div>
              <div>
                <div className="relative z-0 mt-5 md:mt-8 lg:mt-12">
                  <QuoteYellow className="absolute -left-3 -top-2 -z-10" />
                  <p className="mb-0 text-base italic font-medium text-black lg:text-lg">
                    With the help of Bimeco, we were able to develop
                    our structural BIM models for our client. This
                    allowed us to work out clashes in the design
                    models before construction.
                  </p>
                </div>
                <div className="flex items-center gap-2 mt-5 md:gap-4 md:mt-6">
                  <img
                    src={FeedbackFirst}
                    alt="Catherine Li"
                    className="rounded-full size-14 border-[3px] border-main-primary"
                  />
                  <div>
                    <h5 className="mb-0 text-base font-semibold text-black">
                      Catherine Li
                    </h5>
                    <p className="mb-0 text-sm text-gray-500">
                      Director
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="relative z-0 mt-5 md:mt-8 lg:mt-12">
                  <QuoteYellow className="absolute -left-3 -top-2 -z-10" />
                  <p className="mb-0 text-base italic font-medium text-black lg:text-lg">
                    The BIM team worked closely with our
                    subcontractors to create a clash-free BIM model.
                    Parametric Revit families were created
                    specifically for our project.
                  </p>
                </div>
                <div className="flex items-center gap-2 mt-5 md:gap-4 md:mt-6">
                  <img
                    src={FeedbackSecond}
                    alt="Peter Tsai"
                    className="rounded-full size-14 border-[3px] border-main-primary"
                  />
                  <div>
                    <h5 className="mb-0 text-base font-semibold text-black">
                      John Aung
                    </h5>
                    <p className="mb-0 text-sm text-gray-500">
                      Project Manager
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* CTA */}
        <CTA handleClick={handleDefault} />
      </main>

      <Footer />
    </div>
  );
};

export default BIMObjectCreation;
